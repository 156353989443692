import config from "src/config";

function validateAndCreateRegExp(pattern) {
  try {
    // eslint-disable-next-line security/detect-non-literal-regexp
    return new RegExp(pattern);
  } catch (e) {
    console.error(`Invalid regex pattern: ${pattern}`, e);
    return null;
  }
}

export default function getCompanyFromDomain() {
  const ccnCompany = config.COMPANY_CCN;
  const ccaCompany = config.COMPANY_CCA;

  const ccnMatcher = validateAndCreateRegExp(config.CCN_URL_REGEX);
  const ccnScoutProdMatcher = validateAndCreateRegExp(
    config.CCN_PROD_URL_REGEX
  );
  const ccaMatcher = validateAndCreateRegExp(config.CCA_URL_REGEX);
  const ccaScoutProdMatcher = validateAndCreateRegExp(
    config.CCA_PROD_URL_REGEX
  );

  let company = ccnCompany;

  if (
    !ccnMatcher ||
    !ccnScoutProdMatcher ||
    !ccaMatcher ||
    !ccaScoutProdMatcher
  ) {
    console.error("One or more invalid regex patterns found in config");
  } else {
    const baseURI = document.location.origin;

    if (ccnMatcher.test(baseURI) || ccnScoutProdMatcher.test(baseURI)) {
      company = ccnCompany;
    }

    if (ccaMatcher.test(baseURI) || ccaScoutProdMatcher.test(baseURI)) {
      company = ccaCompany;
    }
  }

  return company;
}