import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { cloneDeep, orderBy, takeRight } from "lodash";
import { useHistory } from "react-router-dom";
import jobSearchQueryBuilder from "../../../../services/QueryBuilder/JobSearchQueryBuilder";
import { SAVED_SEARCHES_PAGE } from "../../../../router/routes";
import styles from "./MySaveSearches.module.scss"

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "150px",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  mySaveSearch: {
    "& .MuiSelect-select, .MuiSelect-select:focus,.MuiMenuItem-root ": {
      color: `${styles?.colorPrimary} !important`,
      fontSize: "0.7rem !important",
      fontWeight: "bold !important",
      borderRadius: "4px",
      padding: "4px 0 5px !important",
      backgroundColor: "transparent",
    },
    "& .MuiInput-underline:before,	& .MuiInput-underline:after": {
      borderBottom: "none !important",
    },
  },

  mySaveSearchTxt: {
    color: "#00000099 !important",
    fontSize: "0.9rem !important",
    marginTop: "3px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      marginLeft: "12px",
      marginTop: "6px",
    },
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "0.8rem !important",
    },
  },

  myJobsSort: {
    "@media(min-width: 0px) and (max-width: 768px)": {
      marginLeft: "0px",
    },
  },
  formControlMtIsValue: {
    marginTop: "-10px !important",
    width: "15%",
    "@media(min-width: 0px) and (max-width: 768px)": {
      marginTop: "-5px !important",
      width: "50%",
      marginLeft: "-25px !important",
    },
  },
  formControlMtEmptyValue: {
    marginTop: "-21px !important ",
    width: "15%",
    "@media(min-width: 0px) and (max-width: 768px)": {
      marginTop: "-20px !important",
      width: "50%",
      marginLeft: "-30px !important",
    },
  },
  mySaveSearchSelect: {
    width: "85%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "24px",
    minWidth: "16px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      width: "95%",
      padding: "5px 15px 5px 1px",
    },
  },
  saveSearchSetting: {
    color: "#666666 !important",
    fontSize: "0.8rem",
    paddingTop: "-5px",
    textDecoration: "underline !important",
    textDecorationColor: "#666666 !important",
    cursor: "pointer",
    "&:hover": {
      color: "#D7282F !important",
      textDecoration: "underline",
      textDecorationColor: "#D7282F !important",
    },
  },

  menuItemTxt: {
    color: `${styles?.colorPrimary} !important`,
    fontSize: "0.7rem !important",
    fontWeight: "bold !important",
    padding: "8px 12px !important",
    minHeight: "20px",
  },
  defaultMenuItemTxt: {
    textAlign: "left",
    letterSpacing: "0px",
    color: styles?.colorPrimary,
    opacity: "1",
  },
  defaultLabel: {
    fontWeight: "normal !important",
  },
  searchMenuList: {
    maxWidth: "150px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  savedSearchLink: {
    textAlign: "center",
    position: "sticky",
    bottom: "0px",
    background: "#fff",
    lineHeight: "30px",
    borderTop: "1px solid #00000030",
  },
}));

export default function MySaveSearches({ savedSearches, hash = "", getSavedSearches }) {
  const classes = useStyles();
  const history = useHistory();
  const [mySaveSearch, setmySaveSearch] = React.useState("");
  const { t } = useTranslation();

  const handleChange = (event) => {
    setmySaveSearch(event.target.value);
  };

  const viewJob = (result) => {
    if (result) {
      jobSearchQueryBuilder.handleParamsChange({
        location: JSON.stringify(result.searchCriteria.location),
        specialty: JSON.stringify(result.searchCriteria.specialty),
        geoLocationRadius: result.searchCriteria.geoLocationRadius,
        startDate: result.searchCriteria.startDate,
        shiftTypes: result.searchCriteria.shiftTypes,
        refreshPayFilter: "true",
        shiftLengths: result.searchCriteria.shiftLengths,
        durations: result.searchCriteria.durations,
        salaryMax: result.searchCriteria.salaryMax,
        msp: result.searchCriteria.msp,
        sort: result.searchCriteria.sort,
        jobDetails: result.searchCriteria.jobDetails,
        datePosted: result.searchCriteria.datePosted,
        shouldFilterByQuickApplyEligibility: result.searchCriteria.shouldFilterByQuickApplyEligibility ? "true" : "false"
      });
      getSavedSearches();
    }
  };

  const saveSearchNavigation = () => {
    history.push(SAVED_SEARCHES_PAGE);
  };

  const renderOptions = () => {
    const cloneObj = cloneDeep(savedSearches);
    Object.keys(cloneObj || {}).forEach((searchItem) => {
      cloneObj[searchItem].key = searchItem;
    });
    const renderArray = orderBy(Object.values(cloneObj), ["dateCreated"], ["desc"]);
    return renderArray.map((search) => (
      <MenuItem
        key={search.key}
        value={search.key}
        className={classes.menuItemTxt}
        onClick={() => viewJob(search)}
      >
        <span className={classes.searchMenuList}>{search?.name}</span>
      </MenuItem>
    ));
  };
  const isHashAvailable = Object.keys(savedSearches || {}).includes(hash);
  return (
    <div className={classes.mySaveSearch} style={{ display: "inline" }}>
      <FormControl
        className={isHashAvailable ? classes.formControlMtIsValue : classes.formControlMtEmptyValue}
        variant="standard"
        sx={{ m: 1, minWidth: 200 }}
      >
        <InputLabel className={classes.mySaveSearchTxt}>
          {" "}
          {isHashAvailable && mySaveSearch !== t("savedSearches.mySaveSearchTxt")
            ? t("savedSearches.mySaveSearchTxt")
            : ""}
        </InputLabel>
        <Select
          className={classes.mySaveSearchSelect}
          onChange={handleChange}
          label={t("savedSearches.mySaveSearchTxt")}
          MenuProps={MenuProps}
          value={isHashAvailable ? hash : t("savedSearches.mySaveSearchTxt")}
        >
          <MenuItem
            key={t("savedSearches.mySaveSearchTxt")}
            value={t("savedSearches.mySaveSearchTxt")}
            className={classes.defaultMenuItemTxt}
            hidden
          >
            <span className={(classes.searchMenuList, classes.defaultLabel)}>
              {t("savedSearches.mySaveSearchTxt")}
            </span>
          </MenuItem>
          {renderOptions()}

          <div className={classes.savedSearchLink}>
            <Link className={classes.saveSearchSetting} onClick={() => saveSearchNavigation()}>
              {t("savedSearches.savedSearchSettingsTxt")}
            </Link>
          </div>
        </Select>
      </FormControl>
    </div>
  );
}
